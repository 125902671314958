/**
* Data transfer object for my stays configuration
*/
export class MyStaysConfigurationDTO {

  id: number;

  alwaysLoginViaMyStays: boolean;

  checkPaymentPending: boolean;

  checkAliceOnboardingNotCompleted: boolean;
  
  searchMethods: string;
  
}
