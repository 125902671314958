<div cdkDropListGroup>
  <div class="example-container">
    <div class="ui tiny header">{{ availableItemsLabel }}</div>
    <div cdkDropList id="availableItems" class="example-list" [cdkDropListData]="availableItemsCollection" (cdkDropListDropped)="drop($event)">
      <div class="example-box" *ngFor="let item of availableItemsCollection;" cdkDrag>{{ item.name }}</div>
    </div>
  </div>

  <div class="example-container">
    <div class="ui tiny header">{{ selectedItemsLabel }}</div>
    <div cdkDropList id="selectedItems" class="example-list" [cdkDropListData]="selectedItemsCollection" (cdkDropListDropped)="drop($event)">
      <div class="example-box" *ngFor="let item of selectedItemsCollection;" cdkDrag>{{ item.name }}</div>
    </div>
  </div>
</div>