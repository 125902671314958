import { throwError,  Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http'
import { environment } from '@env/environment';
import {
  EntityListingDTO,
  RequestTypeListingDTO,
  AirportListingDTO,
  CurrencyListingDTO,
  TimezoneListingDTO,
  MyStaysSearchMethodDTO
} from '@models/listings';
import { CountryDTO } from '@models/i18n/CountryDTO';
import { LanguageDTO } from '@models/i18n/LanguageDTO';


// ReSharper disable once InconsistentNaming
const API_BASE_URL: string = environment.apis.core + 'listings';

@Injectable()
export class ListingsService {
  constructor(private httpClient: HttpClient) {
  }

  public getEntities() : Observable<EntityListingDTO[]> {
    return this.httpClient
      .get<EntityListingDTO[]>(API_BASE_URL + '/entities')
      .pipe(
        catchError(e => this.handleError(e))
      );
  }

  public getEntityGroupMembers(entityId: number): Observable<EntityListingDTO[]> {
    return this.httpClient
      .get<EntityListingDTO[]>(API_BASE_URL + '/entities/' + entityId + '/groupmembers')
      .pipe(
        catchError(e => this.handleError(e))
      );
  }

  public getRequestTypes(): Observable<RequestTypeListingDTO[]> {
    return this.httpClient
      .get<RequestTypeListingDTO[]>(API_BASE_URL + '/requestType')
      .pipe(
        catchError(e => this.handleError(e))
      );
  }

  public getCountries(): Observable<CountryDTO[]> {
    return this.httpClient
      .get<CountryDTO[]>(API_BASE_URL + '/countries')
      .pipe(
        catchError(e => this.handleError(e))
      );
  }

  public getLanguages(): Observable<LanguageDTO[]> {
    return this.httpClient
      .get<LanguageDTO[]>(API_BASE_URL + '/languages')
      .pipe(
        catchError(e => this.handleError(e))
      );
  }

  public getAirports() : Observable<AirportListingDTO[]> {
    return this.httpClient
      .get<AirportListingDTO[]>(API_BASE_URL + '/airports')
      .pipe(
        catchError(e => this.handleError(e))
      );
  }

  public getCurrencies(): Observable<CurrencyListingDTO[]> {
    return this.httpClient
      .get<CurrencyListingDTO[]>(API_BASE_URL + '/currencies')
      .pipe(
        catchError(e => this.handleError(e))
      );
  }

  public getTimezones(): Observable<TimezoneListingDTO[]> {
    return this.httpClient
      .get<TimezoneListingDTO[]>(API_BASE_URL + '/timezones')
      .pipe(
        catchError(e => this.handleError(e))
      );
  }

  public getMyStaysSearchMethods(): Observable<MyStaysSearchMethodDTO[]> {
    return this.httpClient
      .get<MyStaysSearchMethodDTO[]>(API_BASE_URL + '/my-stays/search-methods')
      .pipe(
        catchError(e => this.handleError(e))
      );
  }

  private handleError(error: Object) {
    return throwError(error || 'Server error');
  }
}
