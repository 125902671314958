import { Component, Input, Output, EventEmitter } from '@angular/core';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';

@Component({
  selector: 'cdk-drop-list-group',
  templateUrl: './cdk-drop-list-group.component.html',
  styleUrls: ['./cdk-drop-list-group.component.css']
})
export class CdkDropListGroupComponent {

  @Input() availableItemsLabel: string = "Available Items";
  @Input() selectedItemsLabel: string = "Selected Items";
  @Input() availableItemsCollection: Array<any> = [];
  @Input() selectedItemsCollection: Array<any> = [];

  @Output() selectedItemsOnChange: EventEmitter<any> = new EventEmitter();

  drop(event: CdkDragDrop<any[]>) {
    var changed = false;
    if (event.previousContainer === event.container) {
      if (event.container.data && event.container.data.length > 0) {
        moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
        changed = true;
      }
    } else {
      if (event.previousContainer.data && event.container.data) {
        if (event.previousContainer.data.length > 0) {
          const movedItem = event.previousContainer.data[event.previousIndex];
          event.previousContainer.data.splice(event.previousIndex, 1);
          event.container.data.splice(event.currentIndex, 0, movedItem);
          changed = true;
        }
      }
    }
    if (changed && (event.container.id == "selectedItems" || event.previousContainer.id == "selectedItems")) {
      this.selectedItemsOnChange.emit(this.selectedItemsCollection);
    }
  }
}
